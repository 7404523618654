import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container " >
        <div style={{  alignItems: "center"
}}>
      <h2 style={{color:"#3333333"}} >About Us</h2>

        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6"style={{paddingTop:30 }}>
            {" "}
            <img src="img/aboutUs.jpg" className="img-responsive" alt="" style={{width:3000,height:"40%"}} />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <p style={{fontSize:24}}>{props.data ? props.data.paragraph1 : "loading..."}</p>
              <p style={{fontSize:24}}>{props.data ? props.data.paragraph2 : "loading..."}</p>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
